import { EuiLink } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ItemLinkFlyout from '../../../../components/ItemLinkFlyout/ItemLinkFlyout';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../utils/toasts';
import { LinkMembershipOutlets } from '../../../../components/offer/OfferLink/LinkMembershipOutlets';
import { userHasPermission } from '../../../../helper/auth';

const LinkMemberShipAction = ({ Membership, fetchMemberShip }) => {
    const dispatch = useDispatch();
    const outletList = useSelector((state) => state.outlet.outletList);
    const currentLanguage = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);

    const [isLoading, setIsLoading] = useState(false);
    const [offerLinkFlyout, setOfferLinkFlyout] = useState({
        data: [],
        display: false,
        selectedItem: null,
    });
    const [membershipLinkStatus, SetMembershipLinkStatus] = useState({
        link: [],
        unlink: [],
    });

    const offerOutletLinkedCount = outletList?.filter((outlet) =>
        offerLinkFlyout.selectedItem?.outlet_ids?.some(
            (offerOutletId) => offerOutletId === outlet.outlet_data.outlet_id
        )
    )?.length;

    useEffect(() => {
        if (outletList?.length) {
            setOfferLinkFlyout((prevState) => ({
                ...prevState,
                data: outletList,
            }));
        }
    }, [outletList]);

    const toggleFlyout = (offer) => {
        setOfferLinkFlyout((prevState) => ({
            ...prevState,
            display: !prevState.display,
            selectedItem: offer,
        }));
    };

    const initSelectedItems = useCallback((outlet, setIsSelected, selectedMembership) => {
        if (selectedMembership?.outlet_ids?.length) {
            for (const outletId of selectedMembership.outlet_ids) {
                if (outletId === outlet.outlet_data.outlet_id) {
                    setIsSelected(true);
                }
            }
        }
    }, []);
    

    const handleOfferLinkChanges = async (selectedItem) => {
        setIsLoading(true);
        try {
            const payload = {
                linked_outlet_ids: membershipLinkStatus.link?.length ? membershipLinkStatus.link : [],
                unlinked_outlet_ids: membershipLinkStatus.unlink?.length ? membershipLinkStatus.unlink : [],
            };

            if (payload.linked_outlet_ids.length || payload.unlinked_outlet_ids.length) {
                await handleMembershipLinkChange(selectedItem, payload);
            }
        } catch (error) {
            console.error('Error in handleOfferLinkChanges:', error);
            toastsErrorMessage('Oops, there was an error processing the membership links.', dispatch);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMembershipLinkChange = async (selectedItem, payload) => {
        try {
            const response = await LinkMembershipOutlets(selectedItem.id, payload);

            if (response?.success) {
                SetMembershipLinkStatus({
                    link: [],
                    unlink: [],
                });
                fetchMemberShip();
                toastSuccessMessage('Membership outlets updated successfully', dispatch);
            } else {
                toastsErrorMessage(response?.data?.message || 'Failed to update membership outlets', dispatch);
            }
        } catch (error) {
            console.error('Error in handleMembershipLinkChange:', error);
            toastsErrorMessage('Oops, there was an error', dispatch);
        }
    };

    const handleOnChange = useCallback((event, selectedDataInfo, setIsSelected, selectedItem) => {
        const isChecked = event.target.checked;
        setIsSelected(isChecked);

        const outletId = selectedDataInfo?.outlet_data.outlet_id;

        if (isChecked) {
            const doesOutletExist = selectedItem?.outlet_ids?.includes(outletId);

            if (!doesOutletExist) {
                SetMembershipLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...(prevState.link.includes(outletId) ? prevState.link : [...prevState.link, outletId])],
                    unlink: prevState.unlink.filter((id) => id !== outletId),
                }));
            }
        } else {
            // If unchecked, check if the outlet was originally linked
            const wasOriginiallyLinked = selectedItem?.outlet_ids?.includes(outletId);

            if (wasOriginiallyLinked) {
                SetMembershipLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [
                        ...(prevState.unlink.includes(outletId) ? prevState.unlink : [...prevState.unlink, outletId]),
                    ],
                    link: prevState.link.filter((id) => id !== outletId),
                }));
            } else {
                // If not originally linked, just remove from link array
                SetMembershipLinkStatus((prevState) => ({
                    ...prevState,
                    link: prevState.link.filter((id) => id !== outletId),
                }));
            }
        }
    }, []);

    return (
        <>
            <ItemLinkFlyout
                isFlyoutOpen={offerLinkFlyout.display}
                data={offerLinkFlyout.data}
                toggleFlyout={toggleFlyout}
                selectedItem={offerLinkFlyout.selectedItem}
                labelStringLvl1={'inputDisplay'}
                appModule='/offers'
                accessLvl='write'
                isLoading={isLoading}
                initSelectedItems={initSelectedItems}
                setOfferLinkStatus={SetMembershipLinkStatus}
                handleFlyoutSubmitButton={handleOfferLinkChanges}
                statDescription='Linked Outlets'
                statTitle={offerOutletLinkedCount}
                hasStats={true}
                handleOnChange={handleOnChange}
                offerLinkStatus={membershipLinkStatus}
                flyoutHeader={`Link ${offerLinkFlyout?.selectedItem?.translations?.display_name?.[currentLanguage]}`}
            />
            <EuiLink size='s' onClick={() => toggleFlyout(Membership)}>
                Link
            </EuiLink>
        </>
    );
};

export default LinkMemberShipAction;
