import React, { Fragment, useEffect, useState, memo, useCallback } from 'react';
import Pagination from '../../../components/Form/Pagination';
import { EuiLoadingContent } from '@elastic/eui';
import ErrorMessage from '../../../components/UIComponent/ErrorMessage';
import { useSelector } from 'react-redux';
import ListDetails from '../../../components/offer/ListDetails';
import APIV5 from '../../../api/axios/API5';

const MemberShipOfferTable = () => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const refetchMembership = useSelector((state) => state.membershipReducer.refetchMembership);

    const [memberShipList, setMemberShipList] = useState({});

    const fetchMemberShipList = useCallback(async () => {
        setLoading(true);
        try {
            const response = await APIV5.get(
                `restaurants/:restaurantId/memberships?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`
            );
            setMemberShipList(response);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }, [pagination.pageIndex, pagination.pageSize]);

    const handlePageChange = useCallback(
        (page) => {
            setPagination({
                ...pagination,
                pageIndex: page,
            });
        },
        [pagination]
    );

    useEffect(() => {
        fetchMemberShipList();
    }, [refetchMembership, pagination.pageIndex, fetchMemberShipList]);

    if (loading) return <EuiLoadingContent lines={4} />;
    if (error) return <ErrorMessage />;

    return (
        <Fragment>
            {/* 3rd tab  */}
            <ListDetails MemberShipList={memberShipList} fetchMemberShipList={fetchMemberShipList} />
            {memberShipList?.memberships?.pagination?.total > memberShipList?.memberships?.pagination?.per_page && (
                <Pagination
                    totalRecord={memberShipList.memberships?.pagination.total}
                    pageSize={memberShipList.memberships?.pagination?.per_page}
                    activePage={memberShipList.memberships?.pagination?.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
        </Fragment>
    );
};

export default memo(MemberShipOfferTable);
