import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EuiText, EuiBasicTable } from '@elastic/eui';
import LinkMembershipOfferList from '../../modules/Offers/Components/OfferList/LinkMembershipOfferList';
import MembershipOfferListAction from '../../modules/Offers/Components/MembershipOfferListAction';
import { offerTypes } from './Utils/utils';
import { userHasPermission } from '../../helper/auth';

const ListMembershipOffer = ({
    membershipOfferList,
    fetchMembershipOfferList,
    pagination,
    currentTab,
    OFFER_MEMBERSHIP_LIST,
}) => {
    const currentLanguage = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: 'coupon_code',
                name: 'Code',
                render: (code) => <EuiText size='s'>{code}</EuiText>,
            },
            {
                field: 'offer_type',
                name: 'Type',
                align: 'center',
                render: (type) => <EuiText size='s'>{type}</EuiText>,
            },
            {
                field: 'title',
                name: 'Title',
                align: 'center',
                render: (title) => <EuiText size='s'>{title}</EuiText>,
            },
            {
                field: 'description',
                name: 'Description',
                align: 'center',
                width: '25%',
                render: (description) => <EuiText size='s'>{description}</EuiText>,
            },
        ];

        const conditionalColumns = [];

        if (userHasPermission(permissions, '/membership', 'write')) {
            conditionalColumns.push(
                {
                    field: 'link_offer',
                    name: 'Link Membership Offer',
                    align: 'center',
                    render: (MemberOffersList) => (
                        <LinkMembershipOfferList
                            fetchMembershipOfferList={fetchMembershipOfferList}
                            MemberOffersList={MemberOffersList}
                            pagination={pagination}
                        />
                    ),
                },
                {
                    field: 'offer_actions',
                    name: 'Actions',
                    align: 'right',
                    render: (actions) => (
                        <MembershipOfferListAction
                            actions={actions}
                            currentTab={currentTab}
                            OFFER_MEMBERSHIP_LIST={OFFER_MEMBERSHIP_LIST}
                        />
                    ),
                }
            );
        }

        return [...baseColumns, ...conditionalColumns];
    }, [permissions, fetchMembershipOfferList, pagination]);

    const items = useMemo(() => {
        return membershipOfferList?.data?.length
            ? membershipOfferList?.data.map((item) => ({
                  offer_type: offerTypes.find((offer) => offer.value == item.offer_type)?.displayName || 'Unknown',
                  title: item?.translations?.title?.[currentLanguage] || 'No title available',
                  description: item?.translations?.description?.[currentLanguage] || 'No description available',
                  coupon_code: item.coupon_code || 'N/A',
                  ...(userHasPermission(permissions, '/membership', 'write') && { offer_actions: item }),
                  ...(userHasPermission(permissions, '/membership', 'write') && { link_offer: item }),
              }))
            : [];
    }, [currentLanguage, membershipOfferList?.data, permissions]);

    return (
        <EuiBasicTable
            tableCaption='Membership Offer List Details'
            items={items}
            rowHeader='internal_name'
            columns={columns}
            responsive
        />
    );
};

export default React.memo(ListMembershipOffer);
