import APIV5 from '../../../api/axios/API5';

export const LinkMembershipList = async (offerId, payload) => {
    let response;

    try {
        response = await APIV5.patch(`restaurants/:restaurantId/offers/${offerId}/toggle-memberships`, payload);
    } catch (error) {
        response = error;
    }

    return response;
};
