import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiComboBox,
    EuiConfirmModal,
    EuiFormRow,
    EuiOverlayMask,
    EuiPanel,
} from '@elastic/eui';
import API from '../../../api/axios/API';
import _ from 'lodash';
import APIV5 from '../../../api/axios/API5';
import { useSelector } from 'react-redux';
import SelectField from '../../../components/Form/SelectField';
import FormGroupDescription from '../../../components/formGroupDescription';
// import { CLEAR_SELECTED_CUSTOMER, SET_SELECTED_CUSTOMER } from '../../../reduxStore/types/offers';
import PhoneNumberOtpForm from './PhoneNumberOtpForm';
import TextField from '../../../components/Form/TextField';
import { userHasPermission } from '../../../helper/auth';

const ApproveMembership = () => {
    // const dispatch = useDispatch();
    const [confirmOrderCancel, setConfirmOrderCancel] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isShowAddUser, setIsShowAddUser] = useState(false);

    const methods = useForm({
        defaultValues: {
            name: null,
            customer_id: null,
            membership_id: null,
            payment_method: null,
        },
    });

    const {
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors },
        reset,
    } = methods;

    const [searchUser, setSearchUser] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedOptionsCustomer, setSelectedOptionsCustomer] = useState([]);
    const [newUserData, setNewUserData] = useState([]);
    const [newCustomerId, setNewCustomerId] = useState(null);
    const [customerAlreadyPresentPopup, setCustomerAlreadyPresentPopup] = useState(false);
    const [showMembershipSelection, setShowMembershipSelection] = useState(false);
    const [isCustomerAdded, setIsCustomerAdded] = useState(false);

    const fetchSpecificUserList = useCallback(async (mobile_number) => {
        const response = await API.get(`restaurants/:restaurantId/customers/search?search_query=${mobile_number}`);
        return response;
    }, []);

    const onChangeSearchText = useCallback(
        (value) => {
            value?.length && setPhoneNumber(value);
            const delayedQuery = _.debounce((query) => {
                fetchSpecificUserList(query)
                    .then((data) => {
                        const customerList =
                            data?.customers?.map((customer) => ({
                                label: customer.name ? `${customer.name} - ${customer.mobile_number}` : '',
                                mobile_number: customer.mobile_number,
                                id: customer.id,
                                memberships_count: customer.memberships_count,
                            })) || [];
                        setSearchUser(customerList);
                        // customerList;
                        setIsShowAddUser(customerList.length ? false : true);
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            }, 1000);
            delayedQuery(value);
        },
        [fetchSpecificUserList]
    );

    const onChangeCustomerCombo = useCallback(
        (selectedOptions) => {
            const selectedOption = selectedOptions.length > 0 ? selectedOptions[selectedOptions.length - 1] : null;

            if (selectedOption) {
                if (!selectedOption.isCustomButton) {
                    // Dispatch selected customer to Redux
                    // dispatch({
                    //     type: SET_SELECTED_CUSTOMER,
                    //     payload: {
                    //         id: selectedOption.id,
                    //         label: selectedOption.label,
                    //     },
                    // });

                    setSelectedOptionsCustomer(selectedOptions);
                    setValue('customer_id', selectedOption.id, { shouldDirty: true });

                    if (selectedOption.memberships_count === 0) {
                        setShowMembershipSelection(true);
                        setCustomerAlreadyPresentPopup(false);
                        setIsCustomerAdded(false);
                    } else {
                        setCustomerAlreadyPresentPopup(true);
                        setShowMembershipSelection(false);
                        setIsCustomerAdded(false);
                    }
                } else {
                    // Custom button (Add New User) is clicked
                    // handleCustomerButtonClick();
                }
            } else {
                // Reset when no option selected
                // dispatch({ type: CLEAR_SELECTED_CUSTOMER });
                setSelectedOptionsCustomer([]);
                setValue('customer_id', null, { shouldDirty: true });
                setShowMembershipSelection(false);
                setIsCustomerAdded(false);
                setCustomerAlreadyPresentPopup(false);
            }
        },
        [setValue]
    );

    const handleCustomerButtonClick = () => {
        setIsCustomerAdded(true);
        setShowMembershipSelection(true);
        setCustomerAlreadyPresentPopup(false);
    };

    const renderCustomOption = (option, searchValue, contentClassName) => {
        if (option.isCustomButton && userHasPermission(permissions, '/membership', 'write') && isShowAddUser) {
            return (
                <div>
                    <EuiButtonEmpty
                        color='transparent'
                        fill
                        // iconType={isCustomerAdded ? 'minusInCircle' : 'plusInCircleFilled'}
                        iconType='plusInCircleFilled'
                        onClick={handleCustomerButtonClick}
                        // className={isCustomerAdded ? 'remove-customer' : 'add-customer'}
                    >
                        Add Customer
                    </EuiButtonEmpty>
                </div>
            );
        }

        return <div className={contentClassName}>{option.label}</div>;
    };

    const paymentOptions = [
        { value: 'cash', inputDisplay: 'cash' },
        { value: 'online', inputDisplay: 'online' },
        { value: 'card/bank', inputDisplay: 'card/bank' },
    ];

    const [memberShipList, setMemberShipList] = useState({});
    const [error, setError] = useState(null);

    const screenList =
        memberShipList?.data?.map((item) => ({
            value: item.id,
            inputDisplay: item?.internal_name,
        })) || [];

    const fetchMemberShipList = useCallback(async () => {
        setLoading(true);
        try {
            const response = await APIV5.get(`restaurants/:restaurantId/memberships`);
            setMemberShipList(response.memberships);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }, []);

    const createNewCustomer = useCallback(async (data) => {
        try {
            const response = await APIV5.put(`restaurants/:restaurantId/create/customer`, data);
            if (response) {
                setNewCustomerId(response?.customer?.id);
            } else {
                console.error('No customer ID received from the API');
                setError('Failed to create customer');
            }
        } catch (e) {
            setError(e);
            setLoading(false);
            console.error('Error creating customer:', e);
        }
    }, []);

    const membershipIsConform = useCallback(
        async (data) => {
            try {
                const response = await APIV5.put(`restaurants/:restaurantId/link-customer`, data);

                reset({
                    name: null,
                    customer_id: null,
                    membership_id: null,
                    payment_method: null,
                });
                setPhoneNumber('');
                setNewUserData([]);
                setNewCustomerId(null);
                setSelectedOptionsCustomer([]);
                setShowMembershipSelection(false);
                setIsCustomerAdded(false);

                // dispatch({ type: CLEAR_SELECTED_CUSTOMER });
                setConfirmOrderCancel(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        },
        [reset]
    );

    useEffect(() => {
        const name = watch('name');
        if (newUserData?.phone_details) {
            const CreateCustomer = {
                customer_name: name,
                phone_detail_id: newUserData?.phone_details?.id,
            };
            createNewCustomer(CreateCustomer);
        } else {
            console.error('New user data is missing or invalid.');
        }
    }, [newUserData, phoneNumber, watch, createNewCustomer]);

    const handleConfirmMembership = handleSubmit((data) => {
        const formattedData = {
            customer_id: newCustomerId || selectedOptionsCustomer[0]?.id,
            membership_id: data.membership_id,
            payment_method: data.payment_method,
        };
        membershipIsConform(formattedData);
    });

    useEffect(() => {
        fetchMemberShipList();
    }, [fetchMemberShipList]);

    const handleCustomerAlreadyPresentClose = () => {
        setCustomerAlreadyPresentPopup(false);
        // Reset form and selections
        reset({
            customer_id: null,
            membership_id: null,
            payment_method: null,
        });
        setSelectedOptionsCustomer([]);
        setShowMembershipSelection(false);
        // dispatch({ type: CLEAR_SELECTED_CUSTOMER });
    };

    // const customOptions = [
    //     ...searchUser,
    //     ...(searchUser.length === 0 ? { label: 'Add New User', isCustomButton: true } : {}),
    // ];

    return (
        <FormProvider {...methods}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyItems: 'center',
                        gap: '3rem',
                    }}
                >
                    <EuiFormRow
                        isInvalid={!!errors.customer_id}
                        error={errors.customer_id ? errors.customer_id.message : ''}
                        helpText='Search user contact number or name'
                    >
                        <Controller
                            render={() => (
                                <EuiComboBox
                                    async
                                    options={[
                                        ...searchUser,
                                        ...(isShowAddUser ? [{ label: 'Add New User', isCustomButton: true }] : []),
                                    ]}
                                    selectedOptions={selectedOptionsCustomer}
                                    onChange={onChangeCustomerCombo}
                                    renderOption={renderCustomOption}
                                    placeholder='Select one user'
                                    onSearchChange={onChangeSearchText}
                                    isLoading={isLoading}
                                    singleSelection={{ asPlainText: true }}
                                />
                            )}
                            required={!!errors.customer_id}
                            name='customer_id'
                            control={control}
                        />
                    </EuiFormRow>
                </div>

                {userHasPermission(permissions, '/membership', 'write') &&
                    (isCustomerAdded || showMembershipSelection) && (
                        <div>
                            {isCustomerAdded && (
                                <>
                                    <TextField
                                        errors={errors}
                                        // label='Enter your name'
                                        name='name'
                                        placeholder='Please enter your name'
                                        rules={{ required: 'Please enter name' }}
                                        control={control}
                                    />
                                    <PhoneNumberOtpForm
                                        setNewUserData={setNewUserData}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                    />
                                </>
                            )}

                            <div className='' style={{ marginTop: '30px' }}>
                                <FormGroupDescription title='Select Membership'>
                                    <SelectField
                                        errors={errors}
                                        name='membership_id'
                                        options={screenList}
                                        valueOfSelected={watch('membership_id') || null}
                                        onChange={(value) => setValue('membership_id', value)}
                                        placeholder='Please select a membership'
                                        control={control}
                                        rules={{
                                            validate: () =>
                                                watch('membership_id') || 'Please select any one membership',
                                        }}
                                        defaultValue={watch('membership_id') || null}
                                    />
                                </FormGroupDescription>
                            </div>
                            <FormGroupDescription title='Select Payment Method'>
                                <SelectField
                                    errors={errors}
                                    name='payment_method'
                                    options={paymentOptions}
                                    valueOfSelected={watch('payment_method') || null}
                                    placeholder='Please select a payment method'
                                    control={control}
                                    rules={{
                                        validate: () =>
                                            watch('payment_method')?.length || 'Please select any one payment method',
                                    }}
                                    defaultValue={watch('payment_method') || null}
                                />
                            </FormGroupDescription>
                            <EuiButtonEmpty
                                size='s'
                                onClick={() => setConfirmOrderCancel(true)}
                                style={{ backgroundColor: '#0071c2', color: '#ffffff' }}
                            >
                                Confirm Membership
                            </EuiButtonEmpty>
                        </div>
                    )}

                {confirmOrderCancel && (
                    <EuiOverlayMask className='display'>
                        <EuiConfirmModal
                            maxWidth={false}
                            title='Are you sure you want to confirm the membership?'
                            onCancel={() => setConfirmOrderCancel(false)}
                            cancelButtonText="No, don't do it"
                            confirmButtonText='Yes, Confirm'
                            onConfirm={handleConfirmMembership}
                            defaultFocusedButton='cancel'
                        >
                            <style>
                                {`
                                .euiModalFooter .euiButton--danger {
                                background-color: #0071c2 !important;
                                color: #ffffff !important;
                                }
                            `}
                            </style>
                        </EuiConfirmModal>
                    </EuiOverlayMask>
                )}
                {customerAlreadyPresentPopup && (
                    <EuiOverlayMask>
                        <EuiConfirmModal
                            title='Customer already a membership'
                            onCancel={handleCustomerAlreadyPresentClose}
                            cancelButtonText='Cancel'
                            confirmButtonText='Okay'
                            onConfirm={handleCustomerAlreadyPresentClose}
                            defaultFocusedButton='confirm'
                        ></EuiConfirmModal>
                    </EuiOverlayMask>
                )}
            </div>
        </FormProvider>
    );
};

export default ApproveMembership;
