import API from '../../../api/axios/API';
import APIV5 from '../../../api/axios/API5';

export const fetchOfferDetials = async (offerId) => {
    let response = null;

    try {
        response = API.get(`restaurants/:restaurantId/offers/${offerId}`);
    } catch (error) {
        response = error;
    }

    return response;
};
export const fetchMemberShipDetials = async (id) => {
    let response = null;

    try {
        response = APIV5.get(`restaurants/:restaurantId/memberships/${id}`);
    } catch (error) {
        response = error;
    }

    return response;
};