import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSwitch } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tooltip from '../../../components/Global/Tooltip/Tooltip';
import { userHasPermission } from '../../../helper/auth';
import { REFETCH_MEMBERSHIP, SET_MEMBERSHIP } from '../../../reduxStore/types/offers';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import { fetchMemberShipDetials } from '../Api/fetchOfferDetials';
import APIV5 from '../../../api/axios/API5';

const MemberShipActions = ({ actions }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const Membership = useSelector((state) => state.membershipReducer.membership);
    const permissions = useSelector((state) => state.permission.permission);
    const [showToolTip, setShowToolTip] = useState({
        edit: false,
        delete: false,
        switch: false,
    });
    const [isToggle, setIsToggle] = useState(false);

    const onChangeActive = useCallback(
        async (id, newIsActive) => {
            if (window.confirm('Are you sure you want to change Membership status ?')) {
                const toggle = await APIV5.patch(`restaurants/:restaurantId/memberships/${id}/toggle-active`, {
                    is_toggle: newIsActive,
                });
                if (toggle.success) {
                    dispatch({
                        type: REFETCH_MEMBERSHIP,
                    });
                    toastSuccessMessage('Membership status successfully updated', dispatch);
                } else {
                    toastsErrorMessage('We are facing issues, Please try again later.', dispatch);
                }
            }
        },
        [dispatch]
    );

    const handleEditController = useCallback(
        async (id) => {
            await fetchMemberShipDetials(id).then((response) => {
                dispatch({
                    type: SET_MEMBERSHIP,
                    payload: response.membership,
                });
                history.push(`/membership/edit/${id}`);
            });
        },
        [dispatch, history]
    );

    const deleteSelectedOffer = useCallback(
        async (id) => {
            try {
                const deleteOffer = await APIV5.delete(`restaurants/:restaurantId/memberships/${id}`);
                if (deleteOffer.success) {
                    dispatch({
                        type: REFETCH_MEMBERSHIP,
                    });
                }
            } catch (e) {
                toastsErrorMessage('Not deleted, please try later.', dispatch);
            }
        },
        [dispatch]
    );

    const handleDeleteController = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to delete this Membership ?')) {
                await deleteSelectedOffer(id);
            }
        },
        [deleteSelectedOffer]
    );

    // reason for custom tooltip was that, EuiTooltip was messing with the page height.
    // page height behaviour was weird on certain screens.

    return (
        <EuiFlexGroup
            direction='row'
            style={{ position: 'relative' }}
            gutterSize='s'
            justifyContent='flexEnd'
            alignItems='center'
            wrap
            responsive
        >
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/offers', 'write') && (
                    <>
                        <EuiSwitch
                            label=''
                            compressed
                            key={actions.active + 'active'}
                            checked={actions.active ? true : false}
                            onChange={() => {
                                const newIsActive = !actions.active; // Determine the new state
                                setIsToggle(newIsActive); // Update the state
                                onChangeActive(actions.id, newIsActive); // Pass the new value to onChangeActive
                            }}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    switch: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    switch: false,
                                }))
                            }
                        />
                        {showToolTip.switch && <Tooltip content={'Switch for offer visibility'} />}
                    </>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/offers', 'write') && (
                    <>
                        <EuiIcon
                            type='documentEdit'
                            size='original'
                            color='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEditController(actions.id, actions.internal_name)}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    edit: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    edit: false,
                                }))
                            }
                        />
                        {showToolTip.edit && <Tooltip content={'Edit the offer'} />}
                    </>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(permissions, '/membership', 'delete') && (
                    <>
                        <EuiIcon
                            type='trash'
                            size='original'
                            color='danger'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteController(actions.id)}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    delete: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    delete: false,
                                }))
                            }
                        />
                        {showToolTip.delete && <Tooltip content={'Delete the offer'} />}
                    </>
                )}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(MemberShipActions);
