import { EuiFlexItem } from '@elastic/eui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';
import { ReactComponent as BackArrow } from '../../../assets/img/backArrow.svg';

const NavigateBack = (props) => {
    const dispatch = useDispatch();
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const history = useHistory();

    const historyBack = () => {
        if (showFormHandlerPortal) {
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: true,
            });
        } else {
            if (props?.customHistoryBack) {
                history.push(props.customHistoryBackPath);
                return;
            }
            history.goBack();
        }
    };

    return (
        <div>
            {(props.historyBack || props?.customHistoryBack) && (
                <EuiFlexItem style={{ marginRight: '10px', cursor: 'pointer' }} onClick={historyBack}>
                    <BackArrow />
                </EuiFlexItem>
            )}
        </div>
    );
};

export default NavigateBack;
