import APIV5 from '../../../api/axios/API5';

export const LinkMembershipOutlets = async (offerId, payload) => {
    let response;

    try {
        response = await APIV5.patch(`restaurants/:restaurantId/memberships/${offerId}/link-outlet`, payload);
        } catch (error) {
        response = error;
    }

    return response;
};
