import React, { useEffect, useState } from 'react';
import { EuiHorizontalRule, EuiListGroup } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../../../assets/img/dashboardIcon.svg';
import { ReactComponent as LiveOrderIcon } from '../../../assets/img/liveOrder.svg';
import { ReactComponent as PastOrderIcon } from '../../../assets/img/pastOrder.svg';
import { ReactComponent as FailedOrderIcon } from '../../../assets/img/failedOrders.svg';
import { ReactComponent as SendNotificationIcon } from '../../../assets/img/sendNotification.svg';
import { ReactComponent as MenuIcon } from '../../../assets/img/menuIcon.svg';
import { userHasPermission } from '../../../helper/auth';
import { ReactComponent as Group } from '../../../assets/img/group.svg';
import { ReactComponent as Subtitles } from '../../../assets/img/subtitles.svg';
import { ReactComponent as Grade } from '../../../assets/img/grade.svg';
import { ReactComponent as Moped } from '../../../assets/img/moped.svg';
import { ReactComponent as CheckMark } from '../../../assets/img/checkMark.svg';
import { ReactComponent as TopupIcon } from '../../../assets/img/svg/topup.svg';
// import { ReactComponent as CouponImage } from '../../../assets/img/svg/coupon-svgrepo-com.svg';
import { ReactComponent as AbandonedCart } from '../../../assets/img/svg/AbandonedCart.svg';
import { ReactComponent as DeviceSettings } from '../../../assets/img/svg/DeviceSettings.svg';
import { ReactComponent as Offers } from '../../../assets/img/svg/Offers.svg';
import { ReactComponent as OutletSettings } from '../../../assets/img/svg/OutletSetting.svg';
import { ReactComponent as RestaurantSettings } from '../../../assets/img/svg/RestaurantSettings.svg';
import { ReactComponent as UserSettings } from '../../../assets/img/svg/UserSettings.svg';
import { ReactComponent as Printer } from '../../../assets/img/svg/Printer.svg';
import { ReactComponent as RewardPoints } from '../../../assets/img/svg/RewardPoints.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/img/svg/reports-icon.svg';
import { ReactComponent as MediaLibraryIcon } from '../../../assets/img/svg/MediaLibraryIcon.svg';

import { isElectron } from '../../../utils/utils';
import { authModule } from '../../../helper/authModulePermission';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';

export const LeftSideBar = () => {
    const history = useHistory();
    const permissions = useSelector((state) => state.permission.permission);
    const themeMode = useSelector((state) => state.theme.theme);
    const outletList = useSelector((state) => state.outlet.outletList);
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const user = useSelector((state) => state.auth.user.email);

    const onChangeModule = (path) => {
        if (outletList.length > 1) {
            dispatch({
                type: 'set-selected-outlet-id',
                payload: {
                    selectedOutletId: null,
                },
            });
            dispatch({
                type: 'set-selected-outlet',
                payload: {
                    selectedOutlet: {},
                },
            });
        }

        if (showFormHandlerPortal) {
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: true,
            });
        } else {
            history.push(path);
        }
    };

    useEffect(() => {
        let innerItems = [];
        if (userHasPermission(permissions, '/', 'read')) {
            innerItems.push({
                label: 'Dashboard',
                // iconType: 'logoAzureMono',
                icon: <DashboardIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/'),
                isActive: history.location.pathname === '/',
            });
        }

        if (userHasPermission(permissions, '/live-orders', 'read')) {
            innerItems.push({
                label: 'Live Orders',
                // iconType: 'logoAWSMono',
                icon: <LiveOrderIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/live-orders'),
                isActive: history.location.pathname === '/live-orders',
            });
        }
        if (userHasPermission(permissions, '/past-orders', 'read')) {
            innerItems.push({
                label: 'Past Orders',
                // iconType: 'logoAWSMono',
                icon: <PastOrderIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/past-orders'),
                isActive: history.location.pathname === '/past-orders',
            });
        }

        if (userHasPermission(permissions, '/failed-orders', 'read')) {
            innerItems.push({
                label: 'Failed Orders',
                icon: <FailedOrderIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/failed-orders'),
                isActive: history.location.pathname === '/failed-orders',
            });
        }

        if (userHasPermission(permissions, '/abandoned-carts', 'read')) {
            innerItems.push({
                label: 'Abandoned Carts',
                // iconType: 'logoAWSMono',
                icon: <AbandonedCart height='24px' width={'24px'} fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/abandoned-carts'),
                isActive: history.location.pathname === '/abandoned-carts',
            });
        }

        if (userHasPermission(permissions, '/menu', 'read')) {
            innerItems.push({
                label: 'Menu',
                // iconType: 'logoAWSMono',
                icon: <MenuIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/menu'),
                isActive: history.location.pathname === '/menu',
            });
        }
        {
            user === 'jagruti.varu@levaral.com'
                ? innerItems.push({
                      label: 'Menu(Categories list)',
                      icon: <MenuIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                      size: 's',
                      onClick: () => onChangeModule('/menucategories'),
                      isActive: history.location.pathname === '/menucategories',
                  })
                : null;
        }
        if (userHasPermission(permissions, '/media-library', 'read')) {
            innerItems.push({
                label: 'Media Library',
                icon: <MediaLibraryIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/media-library'),
                isActive: history.location.pathname === '/media-library',
            });
        }

        if (userHasPermission(permissions, '/item_availability', 'read')) {
            innerItems.push({
                label: 'Item Availability',
                // iconType: 'logoAWSMono',
                icon: <CheckMark fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/item_availability'),
                isActive: history.location.pathname === '/item_availability',
            });
        }

        if (userHasPermission(permissions, '/order-rating', 'read')) {
            innerItems.push({
                label: 'Order Rating',
                // iconType: 'logoAWSMono',
                icon: <Grade fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/order-rating'),
                isActive: history.location.pathname === '/order-rating',
            });
        }

        if (userHasPermission(permissions, '/customers', 'read')) {
            innerItems.push({
                label: 'Customers',
                // iconType: 'logoAWSMono',
                icon: <Group fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/customers'),
                isActive: history.location.pathname === '/customers',
            });
        }
        if (userHasPermission(permissions, '/notifications', 'read')) {
            innerItems.push({
                label: 'Notifications',
                // iconType: 'logoAWSMono',
                icon: <SendNotificationIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/notifications'),
                isActive: history.location.pathname === '/notifications',
            });
        }
        if (userHasPermission(permissions, '/offers', 'read')) {
            innerItems.push({
                label: 'Offers',
                // iconType: 'logoAWSMono',
                icon: <Offers fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/offers'),
                isActive: history.location.pathname === '/offers',
            });
        }
          if (userHasPermission(permissions, '/coupon_membership', 'read')) {
              innerItems.push({
                  label: 'Coupon Memberships',
                  // iconType: 'logoAWSMono',
                  icon: <Offers fill={themeMode === 'light' ? 'black' : 'white'} />,
                  size: 's',
                  onClick: () => onChangeModule('/coupon_membership'),
                  isActive: history.location.pathname === '/coupon_membership',
              });
          }
        if (userHasPermission(permissions, '/banners', 'read')) {
            innerItems.push({
                label: 'Banners',
                icon: <Subtitles fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/banners'),
                isActive: history.location.pathname === '/banners',
            });
        }
        if (
            authModule(permissions, 'read', 'infrastructure_credit') ||
            authModule(permissions, 'read', 'service_credit')
        ) {
            innerItems.push({
                label: 'Credits',
                // iconType: 'logoAWSMono',
                icon: <TopupIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/credits'),
                isActive: history.location.pathname === '/credits',
            });
        }

        if (userHasPermission(permissions, '/outlet-settings', 'read')) {
            innerItems.push({
                label: 'Store Settings',
                icon: <OutletSettings fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/outlet-settings'),
                isActive: history.location.pathname === '/outlet-settings',
            });
        }

        if (userHasPermission(permissions, '/restaurant-settings', 'read')) {
            innerItems.push({
                label: 'Global Settings',
                icon: <RestaurantSettings fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/restaurant-settings'),
                isActive: history.location.pathname === '/restaurant-settings',
            });
        }

        if (userHasPermission(permissions, '/reports', 'read')) {
            innerItems.push({
                label: 'Reports',
                icon: <ReportsIcon fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/reports'),
                isActive: history.location.pathname === '/reports',
            });
        }

        if (userHasPermission(permissions, '/users', 'read')) {
            innerItems.push({
                label: 'User Settings',
                icon: <UserSettings fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/users'),
                isActive: history.location.pathname === '/users',
            });
        }

        if (userHasPermission(permissions, '/riders', 'read')) {
            innerItems.push({
                label: 'Riders',
                icon: <Moped fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/riders'),
                isActive: history.location.pathname === '/riders',
            });
        }

        if (userHasPermission(permissions, '/bill-payment', 'read')) {
            innerItems.push({
                label: 'Bill Payments',
                iconType: 'folderOpen',
                size: 's',
                onClick: () => onChangeModule('/bill-payment'),
                isActive: history.location.pathname === '/bill-payment',
            });
        }

        if (userHasPermission(permissions, '/table-management', 'read')) {
            innerItems.push({
                label: 'Table Management',
                iconType: 'tableDensityExpanded',
                size: 's',
                onClick: () => onChangeModule('/table-management'),
                isActive: history.location.pathname === '/table-management',
            });
        }

        if (userHasPermission(permissions, '/cashback', 'read')) {
            innerItems.push({
                label: 'Loyalty Cashback',
                icon: <RewardPoints fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => onChangeModule('/cashback'),
                isActive: history.location.pathname === '/cashback',
            });
        }

        if (isElectron()) {
            innerItems.push({
                label: 'Printer Settings',
                icon: <Printer fill={themeMode === 'light' ? 'black' : 'white'} />,
                size: 's',
                onClick: () => {
                    onChangeModule('/printers');
                },
                isActive: history.location.pathname === '/printers',
            });
        }

        innerItems.push({
            label: 'Device Settings',
            icon: <DeviceSettings fill={themeMode === 'light' ? 'black' : 'white'} />,
            size: 's',
            onClick: () => onChangeModule('/device-settings'),
            isActive: history.location.pathname === '/device-settings',
        });

        if (innerItems.length) {
            setItems(innerItems);
        }
    }, []);

    return (
        <>
            <EuiListGroup listItems={items} />

            <EuiHorizontalRule margin='none' />
        </>
    );
};
