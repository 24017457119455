import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkOffer from '../../modules/Offers/Tabs/LinkOffer';
import OfferTable from '../../modules/Offers/Tabs/OfferTable';
import { EuiFlexItem, EuiSpacer, EuiPanel, EuiFlexGroup, EuiTabs, EuiTab } from '@elastic/eui';
import { fetchRestaurantOrderingModes } from '../settings/RestaurantSettings/Utils/utils';
import OfferSelection from '../../modules/Offers/Components/OfferSelection';
import MemberShipOffer from '../../modules/Offers/Tabs/MemberShipOffer';
import MemberShipListOffer from '../../modules/Offers/Tabs/MemberShipListOffer';
import { userHasPermission } from '../../helper/auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const OFFER_LIST_SCREEN = 'offer_list_screen';
const OFFER_LINK_SCREEN = 'offer_link_screen';
const OFFER_MEMBERSHIP_OFFER = 'offer_membership_offer';
const OFFER_MEMBERSHIP_LIST = 'offer_membership_list';

const Offer = () => {
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);

    useEffect(() => {
        if (!restaurantOrderingModes?.length) {
            dispatch(fetchRestaurantOrderingModes());
        }
    }, [dispatch, restaurantOrderingModes]);
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(
        location.search === '?membership-list'
            ? OFFER_MEMBERSHIP_OFFER
            : // : location.search === '?membership-list'
            // ? OFFER_MEMBERSHIP_LIST
            location.search === '?membership-offer-list'
            ? OFFER_MEMBERSHIP_LIST
            : OFFER_LIST_SCREEN
    );
    useLayoutEffect(() => {
        setCurrentTab(
            location.search === '?membership-list'
                ? OFFER_MEMBERSHIP_OFFER
                : // : location.search === '?membership-list'
                // ? OFFER_MEMBERSHIP_LIST
                location.search === '?membership-offer-list'
                ? OFFER_MEMBERSHIP_LIST
                : OFFER_LIST_SCREEN
        );
    }, [location]);
    return (
        <>
            <EuiPanel>
                <EuiFlexGroup justifyContent='spaceBetween'>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize='none'>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={currentTab === OFFER_LIST_SCREEN}
                                        onClick={() => setCurrentTab(OFFER_LIST_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Offer List
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={currentTab === OFFER_LINK_SCREEN}
                                        onClick={() => setCurrentTab(OFFER_LINK_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Link Offer
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                            {userHasPermission(permissions, '/membership', 'read') && (
                                <>
                                    <EuiFlexItem>
                                        <EuiTabs>
                                            <EuiTab
                                                isSelected={currentTab === OFFER_MEMBERSHIP_OFFER}
                                                onClick={() => setCurrentTab(OFFER_MEMBERSHIP_OFFER)}
                                                style={{ paddingRight: '18px' }}
                                            >
                                                Membership List
                                            </EuiTab>
                                        </EuiTabs>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiTabs>
                                            <EuiTab
                                                isSelected={currentTab === OFFER_MEMBERSHIP_LIST}
                                                onClick={() => setCurrentTab(OFFER_MEMBERSHIP_LIST)}
                                                style={{ paddingRight: '18px' }}
                                            >
                                                Membership Offer List
                                            </EuiTab>
                                        </EuiTabs>
                                    </EuiFlexItem>
                                </>
                            )}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <OfferSelection
                        currentTab={currentTab}
                        OFFER_MEMBERSHIP_LIST={OFFER_MEMBERSHIP_LIST}
                        OFFER_MEMBERSHIP_OFFER={OFFER_MEMBERSHIP_OFFER}
                    />
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiPanel>
                {currentTab === OFFER_LIST_SCREEN && <OfferTable />}
                {currentTab === OFFER_LINK_SCREEN && <LinkOffer />}
                {userHasPermission(permissions, '/membership', 'read') && (
                    <>
                        {currentTab === OFFER_MEMBERSHIP_OFFER && <MemberShipOffer />}
                        {currentTab === OFFER_MEMBERSHIP_LIST && (
                            <MemberShipListOffer
                                currentTab={currentTab}
                                OFFER_MEMBERSHIP_LIST={OFFER_MEMBERSHIP_LIST}
                            />
                        )}
                    </>
                )}
            </EuiPanel>
        </>
    );
};

export default React.memo(Offer);
