import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EuiText, EuiBasicTable } from '@elastic/eui';
import MemberShipActions from '../../modules/Offers/Components/MemberShipActions';
import LinkMemberShipAction from '../../modules/Offers/Components/OfferList/LinkMemberShipAction';
import { userHasPermission } from '../../helper/auth';

const ListDetails = ({ MemberShipList, fetchMemberShipList }) => {
    const currentLanguage = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: 'internal_name',
                name: 'Internal Name',
                render: (internalName) => <EuiText size='s'>{internalName}</EuiText>,
            },
            {
                field: 'display_name',
                name: 'Title',
                align: 'center',
                render: (title) => <EuiText size='s'>{title}</EuiText>,
            },
            {
                field: 'price',
                name: 'Price',
                align: 'center',
                render: (price) => (
                    <EuiText size='s'>{typeof price === 'number' ? `${price.toFixed(2)}` : price}</EuiText>
                ),
            },
            {
                field: 'description',
                name: 'Description',
                align: 'center',
                width: '25%',
                render: (description) => <EuiText size='s'>{description}</EuiText>,
            },
            {
                field: 'validity',
                name: 'Expiry',
                align: 'center',
                render: (validity) => <EuiText size='s'>{validity}</EuiText>,
            },
        ];

        const conditionalColumns = [];

        if (userHasPermission(permissions, '/membership', 'write')) {
            conditionalColumns.push(
                {
                    field: 'link_offer',
                    name: 'Link Membership',
                    align: 'center',
                    render: (Membership) => (
                        <LinkMemberShipAction fetchMemberShip={fetchMemberShipList} Membership={Membership} />
                    ),
                },
                {
                    field: 'offer_actions',
                    name: 'Actions',
                    align: 'right',
                    render: (actions) => <MemberShipActions actions={actions} />,
                }
            );
        }

        return [...baseColumns, ...conditionalColumns];
    }, [permissions, fetchMemberShipList]);

    const items = useMemo(() => {
        return MemberShipList?.memberships?.data?.length
            ? MemberShipList?.memberships?.data.map((item) => {
                  const validityText =
                      item.valid_from_date && item.valid_to_date
                          ? `Valid from ${item.valid_from_date} to ${item.valid_to_date}`
                          : item.validity_period
                          ? `Valid for ${item.validity_period} days`
                          : 'No validity information';

                  return {
                      internal_name: item.internal_name || 'No name available',
                      display_name: item?.translations?.display_name?.[currentLanguage] || 'No title available',
                      description: item?.translations?.description?.[currentLanguage] || 'No description available',
                      price: item.price || 'N/A',
                      validity: validityText,
                      ...(userHasPermission(permissions, '/membership', 'write') && { link_offer: item }),
                      ...(userHasPermission(permissions, '/membership', 'write') && { offer_actions: item }),
                  };
              })
            : [];
    }, [currentLanguage, MemberShipList?.data]);

    return (
        <EuiBasicTable
            tableCaption='Membership Details'
            items={items}
            rowHeader='internal_name'
            columns={columns}
            responsive
        />
    );
};

export default React.memo(ListDetails);
