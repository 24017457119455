import React, { useState } from 'react';
import { MainLayout } from '../../components/layout/mainLayout';
import ApproveMembership from './Components/ApproveMembership';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui';
import RedeemMembership from './Components/RedeemMembership';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const CouponMembership = () => {
    const APPROVED_MEMBERSHIP = 'approved_membership';
    const REDEEM_MEMBERSHIP = 'redeem_membership';
    const [currentTab, setCurrentTab] = useState(APPROVED_MEMBERSHIP);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <MainLayout backgroundColorChange={true} title='Coupon Membership' outlet={true}>
            {selectedOutlet ? (
                <>
                    <EuiPanel>
                        <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup gutterSize='none'>
                                    <EuiFlexItem>
                                        <EuiTabs>
                                            <EuiTab
                                                isSelected={currentTab === APPROVED_MEMBERSHIP}
                                                onClick={() => setCurrentTab(APPROVED_MEMBERSHIP)}
                                                style={{ paddingRight: '18px' }}
                                            >
                                                Approved Membership
                                            </EuiTab>
                                        </EuiTabs>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiTabs>
                                            <EuiTab
                                                isSelected={currentTab === REDEEM_MEMBERSHIP}
                                                onClick={() => setCurrentTab(REDEEM_MEMBERSHIP)}
                                                style={{ paddingRight: '18px' }}
                                            >
                                                Redeem Membership
                                            </EuiTab>
                                        </EuiTabs>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            {/* <OfferSelection /> */}
                        </EuiFlexGroup>
                    </EuiPanel>
                    <EuiSpacer />
                    <EuiPanel>
                        {currentTab === APPROVED_MEMBERSHIP ? (
                            <ApproveMembership />
                        ) : (
                            <RedeemMembership selectedOutlet={selectedOutlet} />
                        )}
                    </EuiPanel>
                </>
            ) : (
                <NoOutletMessage />
            )}
        </MainLayout>
    );
};

export default CouponMembership;
