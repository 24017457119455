import React, { useEffect, useState } from 'react';
import {
    EuiFieldText,
    EuiButton,
    EuiForm,
    EuiFormRow,
    EuiCallOut,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui';
import APIV5 from '../../../api/axios/API5';

const PhoneNumberOtpForm = (props) => {
    const { setNewUserData, phoneNumber, setPhoneNumber} = props;
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [remainingTime, setRemainingTime] = useState(30);
    const [ resendTimer, setResendTimer ] = useState(null);


    const startResendTimer = () => {
        setRemainingTime(30);
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        setResendTimer(timer);
    };

    const handleSendOtp = async () => {
        if (!phoneNumber.match(/^\d{10}$/)) {
            setErrorMessage('Please enter a valid 10-digit phone number.');
            return;
        }

        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const response = await APIV5.post('restaurants/:restaurantId/send-otp', {
                mobile_number: phoneNumber,
                country_code: '+91',
            });

            if (response) {
                setSuccessMessage('OTP sent successfully!');
                setIsOtpModalVisible(true);
                setOtpSent(true);
                startResendTimer();
            } else {
                setErrorMessage(response.data.message || 'Failed to send OTP.');
            }
        } catch (error) {
            // setErrorMessage(error.response?.data?.message || 'An error occurred while sending OTP.');
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        if (!otp.match(/^\d{5}$/)) {
            setErrorMessage('Please enter a valid 5-digit OTP.');
            return;
        }

        setLoading(true);
        setErrorMessage('');

        try {
            const response = await APIV5.post('restaurants/:restaurantId/verify-otp', {
                mobile_number: phoneNumber,
                country_code: '+91',
                otp: parseInt(otp, 10),
            });

            if (response) {
                setNewUserData(response);
                setSuccessMessage('OTP verified successfully!');
                setOtpVerified(true);
                setIsOtpModalVisible(false);

                if (resendTimer) {
                    clearInterval(resendTimer);
                }
            } else {
                setErrorMessage(response.data.message || 'Failed to verify OTP.');
            }
        } catch (error) {
            // setErrorMessage(error.response?.data?.message || 'An error occurred while verifying OTP.');
            console.log(`error ==>`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        if (remainingTime === 0) {
            await handleSendOtp();
        }
    };

    const closeModal = () => {
        setIsOtpModalVisible(false);
        setOtp('');

        // Clear any existing timer
        if (resendTimer) {
            clearInterval(resendTimer);
        }
    };

    return (
        <div style={{ marginTop: '14px' }}>
            <EuiForm
                component='form'
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent default form submission
                        if (!otpSent && phoneNumber.match(/^\d{10}$/)) {
                            handleSendOtp();
                        }
                    }
                }}
            >
                <EuiFormRow
                    label='Phone Number'
                    helpText='Enter your 10-digit number'
                    isInvalid={!!errorMessage && !otpSent}
                >
                    <EuiFieldText
                        placeholder='Enter phone number'
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value.replace(/\D/g, '').slice(0, 10));
                            setErrorMessage('');
                        }}
                        isInvalid={!!errorMessage && !otpSent}
                        disabled={otpVerified}
                    />
                </EuiFormRow>

                {errorMessage && !otpSent && (
                    <EuiCallOut title='Error' color='danger' iconType='alert'>
                        <p>{errorMessage}</p>
                    </EuiCallOut>
                )}

                {otpVerified ? (
                    <div className='' style={{ display: 'flex', width: '90rem' }}>
                        <EuiCallOut title='Verified' color='success' iconType='check'>
                            <p>Phone number verified successfully!</p>
                        </EuiCallOut>
                    </div>
                ) : (
                    <EuiButton
                        type='button'
                        onClick={handleSendOtp}
                        isLoading={loading}
                        isDisabled={otpSent || !phoneNumber.match(/^\d{10}$/)}
                        fill
                        color='primary'
                        style={{ marginTop: '1rem' }}
                    >
                        {otpSent ? 'OTP Sent' : 'Send OTP'}
                    </EuiButton>
                )}
            </EuiForm>

            {isOtpModalVisible && (
                <EuiModal onClose={closeModal}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Verify OTP</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiFormRow
                            label='Enter OTP'
                            helpText='5-digit OTP sent to your phone number'
                            isInvalid={!!errorMessage}
                        >
                            <EuiFieldText
                                placeholder='Enter OTP'
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e.target.value.replace(/\D/g, '').slice(0, 5));
                                    setErrorMessage('');
                                }}
                                isInvalid={!!errorMessage}
                            />
                        </EuiFormRow>

                        {errorMessage && (
                            <EuiCallOut title='Error' color='danger' iconType='alert'>
                                <p>{errorMessage}</p>
                            </EuiCallOut>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '1rem',
                            }}
                        >
                            <span>
                                {remainingTime > 0 ? `Resend OTP in ${remainingTime} seconds` : "Didn't receive OTP?"}
                            </span>
                            <EuiButton
                                color='primary'
                                fill={false}
                                onClick={handleResendOtp}
                                isDisabled={remainingTime > 0}
                            >
                                Resend OTP
                            </EuiButton>
                        </div>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButton onClick={closeModal} color='danger' fill={false}>
                            Cancel
                        </EuiButton>
                        <EuiButton
                            onClick={handleVerifyOtp}
                            fill
                            isLoading={loading}
                            isDisabled={!otp.match(/^\d{5}$/)}
                        >
                            Verify OTP
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            )}
        </div>
    );
};

export default PhoneNumberOtpForm;
