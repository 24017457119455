import { EuiLink } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ItemLinkFlyout from '../../../../components/ItemLinkFlyout/ItemLinkFlyout';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../utils/toasts';
import { LinkMembershipList } from '../../../../components/offer/OfferLink/LinkMembershipList';
import useSingleScreenHeightCalc from '../../../../hooks/useSingleScreenHeightCalc';
import APIV5 from '../../../../api/axios/API5';
import { userHasPermission } from '../../../../helper/auth';

const LinkMembershipOfferList = ({ fetchMembershipOfferList, MemberOffersList, pagination }) => {
    const dispatch = useDispatch();
     const permissions = useSelector((state) => state.permission.permission);
    const currentLanguage = useSelector((state) => state.language.languageId);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [popupMemberShipOfferList, setPopupMemberShipOfferList] = useState({});

    const fetchPopupMemberShipList = useCallback(async () => {
        setLoading(true);
        try {
            const response = await APIV5.get(
                `restaurants/:restaurantId/memberships?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`
            );
            setPopupMemberShipOfferList(response?.memberships?.data);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPopupMemberShipList();
    }, [fetchPopupMemberShipList]);
    
    const [ datapopupMemberShipOfferList, setDatapopupMemberShipOfferList ] = useState([]);

    useEffect(() => {
        if (popupMemberShipOfferList.length > 0) {
            const processedList = popupMemberShipOfferList.map((item) => ({
                value: item.id,
                inputDisplay: item.internal_name,
                membership_data: item || [],
            }));
            setDatapopupMemberShipOfferList(processedList);
        }
    }, [popupMemberShipOfferList]);

    const [isLoading, setIsLoading] = useState(false);
    const [offerLinkFlyout, setOfferLinkFlyout] = useState({
        data: [],
        display: false,
        selectedItem: null,
    });
    const [membershipLinkStatus, SetMembershipLinkStatus] = useState({
        link: [],
        unlink: [],
    });

    const offerOutletLinkedCount = offerLinkFlyout?.data?.filter((outlet) =>
        offerLinkFlyout.selectedItem?.linked_memberships?.some((offerOutletId) => offerOutletId === outlet.value)
    )?.length;

    useEffect(() => {
        if (datapopupMemberShipOfferList?.length) {
            setOfferLinkFlyout((prevState) => ({
                ...prevState,
                data: datapopupMemberShipOfferList,
            }));
        }
    }, [datapopupMemberShipOfferList]);

    const toggleFlyout = (offer) => {
        setOfferLinkFlyout((prevState) => ({
            ...prevState,
            display: !prevState.display,
            selectedItem: offer,
        }));
    };

    const initSelectedItems = useCallback((outlet, setIsSelected, selectedMembershipOfferList) => {
        if (selectedMembershipOfferList?.linked_memberships?.length) {
            for (const outletId of selectedMembershipOfferList.linked_memberships) {
                if (outletId === outlet.value) {
                    setIsSelected(true);
                }
            }
        }
    }, []);

    const handleOfferLinkChanges = async (selectedItem) => {
        setIsLoading(true);
        try {
            const payload = {
                link_membership_ids: membershipLinkStatus.link?.length ? membershipLinkStatus.link : [],
                unlink_membership_ids: membershipLinkStatus.unlink?.length ? membershipLinkStatus.unlink : [],
            };

            if (payload.link_membership_ids.length || payload.unlink_membership_ids.length) {
                await handleMembershipLinkChange(selectedItem, payload);
            }
        } catch (error) {
            console.error('Error in handleOfferLinkChanges:', error);
            toastsErrorMessage('Oops, there was an error processing the membership links.', dispatch);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMembershipLinkChange = async (selectedItem, payload) => {
        try {
            const response = await LinkMembershipList(selectedItem.id, payload);

            if (response?.success) {
                SetMembershipLinkStatus({
                    link: [],
                    unlink: [],
                });
                fetchMembershipOfferList();
                toastSuccessMessage('Membership link updated successfully', dispatch);
            } else {
                toastsErrorMessage(response?.data?.message || 'Failed to update membership link', dispatch);
            }
        } catch (error) {
            console.error('Error in handleMembershipLinkChange:', error);
            toastsErrorMessage('Oops, there was an error', dispatch);
        }
    };

    const handleOnChange = useCallback(
        (event, selectedDataInfo, setIsSelected, selectedItem) => {
            const isChecked = event.target.checked;
            setIsSelected(isChecked);

            if (isChecked) {
                const doesOutletExist = selectedItem?.linked_memberships?.filter(
                    (outlet_id) => outlet_id === selectedDataInfo?.value
                );

                if (!doesOutletExist?.length) {
                    SetMembershipLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, selectedDataInfo?.value],
                    }));
                }
            } else {
                const checkedOutletLink = membershipLinkStatus.link.filter(
                    (outletId) => outletId !== selectedDataInfo?.value
                );
                SetMembershipLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedOutletLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedModes = membershipLinkStatus.unlink?.filter((outletId) => {
                    return outletId !== selectedDataInfo?.value;
                });
                SetMembershipLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedModes],
                }));
            } else {
                const matchedModes = selectedItem?.linked_memberships?.filter((outlet_id) => {
                    return outlet_id === selectedDataInfo?.value;
                })?.[0];

                if (matchedModes) {
                    SetMembershipLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedModes],
                    }));
                } else {
                    SetMembershipLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [membershipLinkStatus.link, membershipLinkStatus.unlink]
    );

    return (
        <>
            <ItemLinkFlyout
                isFlyoutOpen={offerLinkFlyout.display}
                data={offerLinkFlyout?.data}
                toggleFlyout={toggleFlyout}
                selectedItem={offerLinkFlyout.selectedItem}
                labelStringLvl1={'inputDisplay'}
                appModule='/offers'
                accessLvl='write'
                isLoading={isLoading}
                initSelectedItems={initSelectedItems}
                setOfferLinkStatus={SetMembershipLinkStatus}
                handleFlyoutSubmitButton={handleOfferLinkChanges}
                statDescription='Linked Memberships'
                statTitle={offerOutletLinkedCount}
                hasStats={true}
                handleOnChange={handleOnChange}
                offerLinkStatus={membershipLinkStatus}
                flyoutHeader={`Link ${offerLinkFlyout?.selectedItem?.translations?.title?.[currentLanguage]}`}
            />
            <EuiLink size='s' onClick={() => toggleFlyout(MemberOffersList)}>
                Link
            </EuiLink>
        </>
    );
};

export default LinkMembershipOfferList;
