import React, { useState, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import _ from 'lodash';
import { EuiFlexItem, EuiFlexGroup, EuiFormRow, EuiComboBox } from '@elastic/eui';
import RadioGroupField from '../../../../components/Form/RadioGroupField';
import API from '../../../../api/axios/API';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const VisibilityAndLinking = () => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const location = useLocation();

    const [isLoading, setLoading] = useState(false);

    const [searchUser, setSearchUser] = useState([]);
    const [selectedOptionsCustomer, setSelectedCustomer] = useState(
        watch('customer_ids') === false ? watch('selected_customers') : []
    );

    const selected_customers = useWatch({
        control,
        name: 'selected_customers',
    });

    const customer_ids = useWatch({
        control,
        name: 'customer_ids',
    });

    useEffect(() => {
        if (!customer_ids) {
            setSelectedCustomer(selected_customers);
        }
    }, [selected_customers, customer_ids]);

    const fetchSpecificUserList = useCallback(async (mobile_number) => {
        const user = await API.get(`restaurants/:restaurantId/customers/search?search_query=${mobile_number}`);
        return user;
    }, []);

    const onChangeCustomerCombo = useCallback(
        (selectedOptions) => {
            let selectedUsers = [];
            selectedOptions.forEach((user) => {
                selectedUsers.push(user.id);
            });
            setSelectedCustomer(selectedOptions);
            setValue('selected_users', selectedUsers, { shouldDirty: true });
        },
        [setValue]
    );

    const onSearchChange = useCallback(
        (searchValue) => {
            fetchSpecificUserList(searchValue)
                .then((data) => {
                    const customerList = [];
                    data?.customers?.forEach((customer) => {
                        customerList.push({
                            label: customer.name ? `${customer.name} - ${customer.mobile_number}` : '',
                            mobile_number: customer.mobile_number,
                            id: customer.id,
                        });
                    });
                    setSearchUser(customerList);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        },
        [fetchSpecificUserList]
    );

    const delayedQuery = _.debounce(async (query) => {
        await onSearchChange(query);
    }, 1000);

    const onChangeSearchText = useCallback(
        (value) => {
            if (!value) return;
            setLoading(true);
            delayedQuery(value);
        },
        [delayedQuery]
    );

    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow fullWidth={true}>
                        <RadioGroupField
                            options={[
                                {
                                    id: 'card_no',
                                    label: 'No',
                                },
                                {
                                    id: 'card_yes',
                                    label: 'Yes',
                                },
                            ]}
                            idSelected={watch('display_in_cart_tab') ? 'card_yes' : 'card_no'}
                            onChange={(id) =>
                                setValue('display_in_cart_tab', id === 'card_yes' ? true : false, {
                                    shouldDirty: true,
                                })
                            }
                            legend={'Should this offer be visible on the Coupon Page in cart?'}
                            name='display_in_cart_tab'
                            defaultValue={watch('display_in_cart_tab') ? true : false}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow fullWidth={true}>
                        <RadioGroupField
                            options={[
                                {
                                    id: 'offer_no',
                                    label: 'No',
                                },
                                {
                                    id: 'offer_yes',
                                    label: 'Yes',
                                },
                            ]}
                            idSelected={watch('display_in_offers_tab') === 'offer_yes' ? 'offer_yes' : 'offer_no'}
                            onChange={(id) => {
                                setValue('display_in_offers_tab', id === 'offer_yes' ? 'offer_yes' : 'offer_no', {
                                    shouldDirty: true,
                                });
                            }}
                            legend={'Should this offer be visible on Offer Tab in app?'}
                            name='display_in_offers_tab'
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                {!location.pathname.includes('/membership/offers/create') &&
                    !location.pathname.includes('/membership/offers/edit') && (
                        <EuiFlexItem>
                            <EuiFormRow fullWidth={true}>
                                <RadioGroupField
                                    options={[
                                        {
                                            id: 'all_user',
                                            label: 'Apply to all user',
                                        },
                                        {
                                            id: 'specific_user',
                                            label: 'Apply for specific user',
                                        },
                                    ]}
                                    idSelected={watch('customer_ids') === false ? 'specific_user' : 'all_user'}
                                    onChange={(id) =>
                                        setValue('customer_ids', id === 'all_user' ? true : false, {
                                            shouldDirty: true,
                                        })
                                    }
                                    legend={'Decide this offer is applied to all user or specific user'}
                                    name='customer_ids'
                                    defaultValue={true}
                                />
                            </EuiFormRow>
                            {watch('customer_ids') === false && (
                                <EuiFormRow
                                    isInvalid={errors.selected_users}
                                    error={errors.selected_users ? errors.selected_users.message : ''}
                                    helpText='Search user contact number or name'
                                >
                                    <Controller
                                        render={() => (
                                            <EuiComboBox
                                                async
                                                options={searchUser}
                                                selectedOptions={selectedOptionsCustomer}
                                                onChange={onChangeCustomerCombo}
                                                placeholder='Select one or more users'
                                                onSearchChange={onChangeSearchText}
                                                isLoading={isLoading}
                                            />
                                        )}
                                        rules={{
                                            validate: (value) =>
                                                (watch('selected_users') && watch('selected_users').length > 0) ||
                                                'Please select atleast one user',
                                        }}
                                        required={errors.selected_users}
                                        name='selected_users'
                                        control={control}
                                    />
                                </EuiFormRow>
                            )}
                        </EuiFlexItem>
                    )}
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(VisibilityAndLinking);
