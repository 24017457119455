import React, { useRef, useEffect } from 'react';
import {
    EuiFocusTrap,
    EuiPage,
    EuiPageBody,
    EuiPageContentBody,
    EuiText,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiProvider,
    EuiFlexGroup,
    EuiButtonEmpty,
} from '@elastic/eui';
import { Header } from '../header';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../reduxStore/types/outlet';
import '../../../styles/style.css';
import OutletSelection from './OutletSelection';
import NavigateBack from './NavigateBack';

export const MainLayout = (props) => {
    const navDrawerRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const languageId = useSelector((state) => state.language.languageId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const themeMode = useSelector((state) => state.theme.theme);
    const creditAlerts = useSelector((state) => state.creditAlerts.alerts);

    useEffect(() => {
        if (navDrawerRef && navDrawerRef.current) {
            navDrawerRef.current.collapseDrawer();
        }
    }, []);

    useEffect(() => {
        let outletArray = [];
        outletList.map((outlet) => {
            outletArray.push({
                value: outlet.value,
                inputDisplay: outlet.outlet_data.translations.name[languageId],
                outlet_data: outlet.outlet_data,
            });
        });

        dispatch({
            type: types.SET_OUTLET_LIST,
            payload: {
                outletList: outletArray,
            },
        });
    }, [languageId]);

    return (
        <>
            <EuiFocusTrap>
                <EuiProvider colorMode={themeMode}>
                    <Header navDrawerRef={navDrawerRef} />
                    <EuiPage
                        style={{
                            backgroundColor:
                                props.backgroundColorChange && themeMode === 'light'
                                    ? 'rgba(244, 244, 244, 1)'
                                    : themeMode === 'light'
                                    ? '#ffffff'
                                    : '#1a1b20',
                            width: '100%',
                            overflowX: 'hidden',
                            height: '100vh',
                            maxHeight: props.isSingleScreen ? '100vh' : 'auto',
                            overflowY: props.isSingleScreen ? 'hidden' : 'visible',
                        }}
                        className='scrollbar'
                    >
                        <EuiPageBody
                            style={{
                                paddingTop:
                                    creditAlerts.low_credit_outlets_message &&
                                    creditAlerts.low_credit_restaurants_message
                                        ? '150px'
                                        : creditAlerts.low_credit_outlets_message
                                        ? '100px'
                                        : creditAlerts.low_credit_restaurants_message
                                        ? '100px'
                                        : '50px',
                                ...(props?.isOverflowHidden && { overflow: 'hidden' }),
                            }}
                        >
                            {/* Page title */}
                            {props.title && (
                                <EuiFlexItem style={{ padding: '0 20px' }}>
                                    <EuiFlexItem
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <EuiFlexItem
                                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                        >
                                            {/* Pos back button */}

                                            <NavigateBack
                                                historyBack={props.historyBack}
                                                customHistoryBackPath={props?.customHistoryBackPath}
                                                customHistoryBack={props?.customHistoryBack}
                                            />

                                            <EuiText>
                                                <h2>
                                                    <b>{props.title}</b>
                                                </h2>
                                            </EuiText>
                                        </EuiFlexItem>

                                        {/* Oultet dropdown */}
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                wrap
                                                responsive
                                                alignItems='center'
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {props.quickNavigations?.length ? (
                                                    <>
                                                        {props.quickNavigations.map((navigateTo, index) => {
                                                            return (
                                                                <EuiButtonEmpty
                                                                    key={index}
                                                                    iconType={'popout'}
                                                                    onClick={() => history.push(navigateTo.href)}
                                                                >
                                                                    Go to {navigateTo.label}
                                                                </EuiButtonEmpty>
                                                            );
                                                        })}
                                                    </>
                                                ) : null}

                                                <OutletSelection
                                                    allowAllOutletsSelection={props.allowAllOutletsSelection}
                                                    outlet={props.outlet}
                                                />
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                    </EuiFlexItem>
                                    <EuiHorizontalRule margin='xs' />
                                </EuiFlexItem>
                            )}

                            <EuiPageContentBody style={{ padding: '12px 1.5%' }}>
                                <>{props.children}</>
                            </EuiPageContentBody>
                        </EuiPageBody>
                    </EuiPage>
                </EuiProvider>
            </EuiFocusTrap>
        </>
    );
};
