import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../../components/layout/mainLayout';
import { SET_MEMBERSHIP } from '../../../reduxStore/types/offers';
import useQuery from '../../../utils/useQuery';
import { MemberShipListInputs } from '../../../components/MemberShip/MemberShipListInputs';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const MemberShipCreateEditPage = () => {
    const dispatch = useDispatch();
    const Membership = useSelector((state) => state.membershipReducer.membership);
    const { membershipId: MemberShipId } = useParams();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        return function cleanup() {
            dispatch({
                type: SET_MEMBERSHIP,
                payload: {},
            });
        };
    }, [ dispatch ]);
    

    if (!MemberShipId && location.pathname !== '/membership-list') {
        history.push('/offers');
    }

    return (
        <MainLayout
            backgroundColorChange={true}
            title={isEmpty(MemberShipId) ? 'Membership List' : 'Modify Memberhip'}
            historyBack={false}
            customHistoryBack={true}
            customHistoryBackPath={'/offers?membership-list'}
        >
            <MemberShipListInputs membership={Membership} selectedMembership={MemberShipId} />
        </MainLayout>
    );
};

export default MemberShipCreateEditPage;
