import * as types from '../types/offers';

const initialState = {
    membership: {},
    refetchMembership: false,
};

function membershipReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_MEMBERSHIP:
            return {
                ...state,
                membership: action.payload,
            };
        case types.REFETCH_MEMBERSHIP:
            return {
                ...state,
                refetchMembership: !state.refetchMembership,
            };

        default:
            return state;
    }
}
export default membershipReducer;
