import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSwitch } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../../api/axios/API';
import Tooltip from '../../../components/Global/Tooltip/Tooltip';
import { userHasPermission } from '../../../helper/auth';
import { REFETCH_OFFERS, SET_OFFERS } from '../../../reduxStore/types/offers';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import { fetchOfferDetials } from '../Api/fetchOfferDetials';

const MembershipOfferListAction = ({ actions, currentTab, OFFER_MEMBERSHIP_LIST }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const [showToolTip, setShowToolTip] = useState({
        edit: false,
        delete: false,
        switch: false,
    });

    const onChangeActive = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to change membership offer status ?')) {
                const toggle = await API.patch(`restaurants/:restaurantId/offers/${id}/toggle-active `);
                if (toggle.success) {
                    dispatch({
                        type: REFETCH_OFFERS,
                    });
                    toastSuccessMessage('Membership offer status successfully updated', dispatch);
                } else {
                    toastsErrorMessage('We are facing issues, Please try again later.', dispatch);
                }
            }
        },
        [dispatch]
    );

    const handleEditController = useCallback(
        async (id, value) => {
            await fetchOfferDetials(id).then((response) => {
                dispatch({
                    type: SET_OFFERS,
                    payload: response.offers,
                });
                history.push(`/membership/offers/edit/${id}?offerType=${value}`);
            });
        },
        [dispatch, history]
    );

    const deleteSelectedOffer = useCallback(
        async (id) => {
            try {
                const deleteOffer = await API.delete(`restaurants/:restaurantId/offers/${id}`);
                if (deleteOffer.success) {
                    dispatch({
                        type: REFETCH_OFFERS,
                    });
                }
            } catch (e) {
                toastsErrorMessage('Not deleted, please try later.', dispatch);
            }
        },
        [dispatch]
    );

    const handleDeleteController = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to delete this Membership offer ?')) {
                await deleteSelectedOffer(id);
            }
        },
        [deleteSelectedOffer]
    );

    // reason for custom tooltip was that, EuiTooltip was messing with the page height.
    // page height behaviour was weird on certain screens.

    return (
        <EuiFlexGroup
            direction='row'
            style={{ position: 'relative' }}
            gutterSize='s'
            justifyContent='flexEnd'
            alignItems='center'
            wrap
            responsive
        >
            <EuiFlexItem grow={false}>
                {userHasPermission(
                    permissions,
                    currentTab === OFFER_MEMBERSHIP_LIST ? '/membership' : '/offers',
                    'write'
                ) && (
                    <>
                        <EuiSwitch
                            label=''
                            compressed
                            key={actions.active + 'active'}
                            checked={actions.active ? true : false}
                            onChange={() => onChangeActive(actions.id)}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    switch: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    switch: false,
                                }))
                            }
                        />
                        {showToolTip.switch && <Tooltip content={'Switch for Membership offer visibility'} />}
                    </>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(
                    permissions,
                    currentTab === OFFER_MEMBERSHIP_LIST ? '/membership' : '/offers',
                    'write'
                ) && (
                    <>
                        <EuiIcon
                            type='documentEdit'
                            size='original'
                            color='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEditController(actions.id, actions.offer_type)}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    edit: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    edit: false,
                                }))
                            }
                        />
                        {showToolTip.edit && <Tooltip content={'Edit the Membership offer'} />}
                    </>
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                {userHasPermission(
                    permissions,
                    currentTab === OFFER_MEMBERSHIP_LIST ? '/membership' : '/offers',
                    'delete'
                ) && (
                    <>
                        <EuiIcon
                            type='trash'
                            size='original'
                            color='danger'
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteController(actions.id)}
                            onMouseOver={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    delete: true,
                                }))
                            }
                            onMouseOut={() =>
                                setShowToolTip((prevState) => ({
                                    ...prevState,
                                    delete: false,
                                }))
                            }
                        />
                        {showToolTip.delete && <Tooltip content={'Delete the Membership offer'} />}
                    </>
                )}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(MembershipOfferListAction);
