import React from 'react';
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { EuiCheckbox, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, EuiTitle } from '@elastic/eui';
import AvailableOfferTable from './AvailableOfferTable';
import RemainingOfferDetails from './RemainingOfferDetails';

const FirstPanel = (props) => {
    const {
        searchUser,
        selectedOptionsCustomer,
        onChangeCustomerCombo,
        isoffersDetails,
        isLoading,
        onChangeSearchText,
        handleOfferSelection,
        offersDetails,
        errors,
        setValue,
        control,
        watch,
        remainingOfferDetails,
        availableOfferDetails,
        onOfferRedeem,
    } = props;
    const methods = useForm({
        defaultValues: {
            // customer_id: null,
            // membership_id: null,
            // payment_method: null,
        },
    });
    // const {
    //     control,
    //     watch,
    //     setValue,
    //     handleSubmit,
    //     formState: { errors },
    //     reset,
    // } = methods;
    return (
        <FormProvider {...methods}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '3rem', padding: '15px' }}>
                <EuiFormRow
                    isInvalid={errors.selected_users}
                    error={errors.selected_users ? errors.selected_users.message : ''}
                    helpText='Search user contact number or name'
                >
                    <Controller
                        render={() => (
                            <EuiComboBox
                                async
                                options={searchUser}
                                selectedOptions={selectedOptionsCustomer}
                                onChange={onChangeCustomerCombo}
                                placeholder='Select one or more users'
                                onSearchChange={onChangeSearchText}
                                isLoading={isLoading}
                                singleSelection={{ asPlainText: true }}
                            />
                        )}
                        required={errors.selected_users}
                        name='selected_users'
                        control={control}
                    />
                </EuiFormRow>
                <EuiFlexGroup direction='column'>
                    <EuiText size='s' children={<p>Selected User</p>} />
                    <EuiTitle size='s'>
                        <span>{selectedOptionsCustomer?.[0]?.label || 'No User selected'}</span>
                    </EuiTitle>
                </EuiFlexGroup>
                <div className=''>
                    {isoffersDetails ? (
                        offersDetails.length > 0 ? (
                            <h3>Available Coupon</h3>
                        ) : (
                            <h3>There are no Available offers</h3>
                        )
                    ) : null}

                    <AvailableOfferTable
                        availableOfferDetails={availableOfferDetails}
                        handleOfferSelection={handleOfferSelection}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        onOfferRedeem={onOfferRedeem}
                    />
                </div>

                <div className=''>
                    {isoffersDetails ? (
                        remainingOfferDetails?.length > 0 ? (
                            <h3>Used Coupon</h3>
                        ) : (
                            <h3>There are no Used Coupon</h3>
                        )
                    ) : null}

                    <RemainingOfferDetails remainingOfferDetails={remainingOfferDetails} />
                </div>
            </div>
        </FormProvider>
    );
};

export default FirstPanel;
