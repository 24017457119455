import React, { useMemo, useState, memo } from 'react';
import { EuiCheckbox, EuiDataGrid, EuiFlexItem } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const RemainingOfferDetails = (props) => {
    const { remainingOfferDetails } = props;
    const columns = useMemo(() => {
        return [
            {
                id: 'select',
                display: 'Used',
                displayAsText: 'Used',
                isSortable: true,
            },
            {
                id: 'label',
                display: 'Label',
                displayAsText: 'Label',
                isSortable: true,
            },
            {
                id: 'title',
                display: 'Title',
                displayAsText: 'Title',
                isSortable: true,
            },
            {
                id: 'description',
                display: 'Description',
                displayAsText: 'Description',
                isSortable: true,
            },
        ];
    }, []);

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    const mappedItems = useMemo(() => {
        return remainingOfferDetails?.length > 0
            ? remainingOfferDetails.map((item, index) => ({
                  select: index + 1,
                  label: item.label,
                  title: item.title || 'N/A',
                  description: item.description || 'N/A',
              }))
            : [];
    }, [remainingOfferDetails]);

    return (
        <EuiDataGrid
            aria-label='Offers List Datagrid'
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            rowCount={mappedItems.length}
            rowHeight={50}
            height='500px'
            gridStyle={{
                stripes: true,
                border: 'all',
                header: 'underline',
            }}
            renderCellValue={({ rowIndex, columnId }) => {
                const row = mappedItems[rowIndex];
                return row[columnId];
            }}
        />
    );
};

export default memo(RemainingOfferDetails);
