import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import API from '../../../api/axios/API';
import APIV5 from '../../../api/axios/API5';
import FirstPanel from './FirstPanel';
import useSingleScreenHeightCalc from '../../../hooks/useSingleScreenHeightCalc';

const RedeemMembership = ({ selectedOutlet }) => {
    const methods = useForm({
        defaultValues: {
            customer_id: null,
            membership_id: null,
            payment_method: null,
        },
    });

    const {
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors },
        reset,
    } = methods;
    const [offersDetails, SetOffersDeatils] = useState([]);
    const [availableOfferDetails, SetAvailableCouponDetails] = useState([]);
    const [remainingOfferDetails, SetRemainingOfferDetails] = useState([]);
    const { screenHeight } = useSingleScreenHeightCalc();
    const [isoffersDetails, SetIsoffersDetails] = useState(false);
    const [availableOffers, setAvailableOffers] = useState([]);
    const [remainingOffers, setRemainingOffers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [searchUser, setSearchUser] = useState([]);
    const [redeemedOffers, setRedeemedOffers] = useState([]);
    const [selectedOptionsCustomer, setSelectedCustomer] = useState(
        watch('customer_ids') === false ? watch('selected_customers') : []
    );
    const selected_customers = useWatch({
        control,
        name: 'selected_customers',
    });

    const customer_ids = useWatch({
        control,
        name: 'customer_ids',
    });

    useEffect(() => {
        if (!customer_ids) {
            setSelectedCustomer(selected_customers);
        }
    }, [selected_customers, customer_ids]);
    const fetchSpecificUserList = useCallback(async (mobile_number) => {
        const user = await API.get(`restaurants/:restaurantId/customers/search?search_query=${mobile_number}`);
        return user;
    }, []);

    const onChangeCustomerCombo = useCallback(
        (selectedOptions) => {
            let selectedUsers = [];
            selectedOptions.forEach((user) => {
                selectedUsers.push(user.id);
            });
            setSelectedCustomer(selectedOptions);
            setValue('selected_users', selectedUsers, { shouldDirty: true });
        },
        [setValue]
    );

    // const onChangeCustomerCombo = useCallback(
    //     (selectedOptions) => {
    //         // Select only the last chosen option
    //         const selectedOption = selectedOptions.length > 0 ? selectedOptions[selectedOptions.length - 1] : null;

    //         if (selectedOption) {
    //             console.log(`selectedOption ==>`,selectedOption)
    //             // Update state with the last selected customer
    //             setSelectedCustomer(selectedOption);
    //             setValue('selected_users', [selectedOption.id], { shouldDirty: true });
    //         } else {
    //             // Reset state when no customer is selected
    //             setSelectedCustomer([]);
    //             setValue('selected_users', [], { shouldDirty: true });
    //         }
    //     },
    //     [setValue]
    // );

    const onSearchChange = useCallback(
        (searchValue) => {
            fetchSpecificUserList(searchValue)
                .then((data) => {
                    const customerList = [];
                    data?.customers?.forEach((customer) => {
                        customerList.push({
                            label: customer.name ? `${customer.name} - ${customer.mobile_number}` : '',
                            mobile_number: customer.mobile_number,
                            id: customer.id,
                        });
                    });
                    setSearchUser(customerList);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        },
        [fetchSpecificUserList]
    );

    const delayedQuery = _.debounce(async (query) => {
        await onSearchChange(query);
    }, 1000);

    const onChangeSearchText = useCallback(
        (value) => {
            if (!value) return;
            setLoading(true);
            delayedQuery(value);
        },
        [delayedQuery]
    );
    const processOffers = useCallback((offers) => {
        if (offers.length === 0) return;

        const processedAvailableOffers = offers.map((offer) => {
            const { max_usage_count_per_user, offer_usage_count_per_customer } = offer;

            const availableOffer = max_usage_count_per_user - offer_usage_count_per_customer;

            return {
                availableOffer,
                remainingOffer: offer_usage_count_per_customer,
            };
        });

        setAvailableOffers(processedAvailableOffers.map((offer) => offer.availableOffer));
        setRemainingOffers(processedAvailableOffers.map((offer) => offer.remainingOffer));
    }, []);
    const handleOfferSelection = useCallback(
        (offerId) => {
            setValue('offers_id', offerId);
        },
        [setValue]
    );
    const fetchOffersList = useCallback(async () => {
        SetIsoffersDetails(false);
        try {
            if (selectedOptionsCustomer?.length > 0) {
                const customerId = selectedOptionsCustomer[0]?.id;
                const response = await APIV5.get(
                    `restaurants/:restaurantId/customer/offer-usage?outlet_id=${selectedOutlet}&customer_id=${customerId}`
                );

                SetOffersDeatils(response.offers);
                SetIsoffersDetails(true);
                processOffers(response.offers);
            } else {
                console.error('No customer selected');
            }
        } catch (error) {
            console.error('Error fetching offers list:', error);
        }
    }, [selectedOptionsCustomer, processOffers]);

    useEffect(() => {
        fetchOffersList();
    }, [fetchOffersList]);

    const generateAvailableOfferItems = () => {
        return offersDetails.flatMap((offer, offerIndex) => {
            const availableOfferCount = availableOffers[offerIndex] || 0;

            return Array.from({ length: availableOfferCount }, (_, index) => ({
                id: offer.id,
                label: `${offer.coupon_code} (${index + 1}/${availableOfferCount})`,
                title: offer.translations?.title?.[1],
                description: offer.translations?.description?.[1],
            }));
        });
    };

    const generatereMainingOffersItems = () => {
        SetRemainingOfferDetails(
            offersDetails.flatMap((offer, offerIndex) => {
                const remainingOffer = remainingOffers[offerIndex] || 0;

                return Array.from({ length: remainingOffer }, (_, index) => ({
                    id: offer.id,
                    label: `${offer.coupon_code} (${index + 1}/${remainingOffer})`,
                    title: offer.translations?.title?.[1],
                    description: offer.translations?.description?.[1],
                }));
            })
        );
    };

    useEffect(() => {
        const availableOfferDetails = generateAvailableOfferItems();
        SetAvailableCouponDetails(availableOfferDetails);

        SetRemainingOfferDetails(
            offersDetails.flatMap((offer, offerIndex) => {
                const remainingOffer = remainingOffers[offerIndex] || 0;

                return Array.from({ length: remainingOffer }, (_, index) => ({
                    id: offer.id,
                    label: `${offer.coupon_code} (${index + 1}/${remainingOffer})`,
                    title: offer.translations?.title?.[1],
                    description: offer.translations?.description?.[1],
                }));
            })
        );
    }, [offersDetails, availableOffers, selectedOutlet, selectedOptionsCustomer, remainingOffers]);

    const redeemOffer = useCallback(
        async (offerId) => {
            try {
                if (selectedOptionsCustomer?.length === 0) {
                    console.error('No customer selected');
                    return;
                }

                const customerId = selectedOptionsCustomer[0].id;

                const payload = {
                    customer_id: customerId,
                    offer_id: offerId,
                };

                const response = await APIV5.put(`restaurants/:restaurantId/customer/redeem-offer`, payload);

                setRedeemedOffers((prev) => [
                    ...prev,
                    {
                        offerId,
                        customerId,
                        timestamp: new Date(),
                    },
                ]);

                fetchOffersList();

                return response;
            } catch (error) {
                console.error('Error redeeming offer:', error);
                // Optionally handle error (show toast, reset checkbox, etc.)
            }
        },
        [selectedOptionsCustomer]
    );

    return (
        <FirstPanel
            control={control}
            searchUser={searchUser}
            errors={errors}
            setValue={setValue}
            watch={watch}
            selectedOptionsCustomer={selectedOptionsCustomer}
            onChangeCustomerCombo={onChangeCustomerCombo}
            onChangeSearchText={onChangeSearchText}
            isLoading={isLoading}
            handleOfferSelection={handleOfferSelection}
            isoffersDetails={isoffersDetails}
            offersDetails={offersDetails}
            availableOfferDetails={availableOfferDetails}
            remainingOfferDetails={remainingOfferDetails}
            onOfferRedeem={redeemOffer}
        />
    );
};

export default RedeemMembership;
