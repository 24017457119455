import React, { useMemo, useState, memo } from 'react';
import { EuiCheckbox, EuiConfirmModal, EuiDataGrid, EuiFlexItem, EuiOverlayMask } from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { userHasPermission } from '../../../helper/auth';
import { useSelector } from 'react-redux';

const AvailableOfferTable = (props) => {
    const { availableOfferDetails, handleOfferSelection, control, setValue, onOfferRedeem } = props;
    const [userSureWantToUsePopup, setUserSureWantToUsePopup] = useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const permissions = useSelector((state) => state.permission.permission);

    const columns = useMemo(() => {
        return [
            userHasPermission(permissions, '/membership', 'write')
                ? {
                      id: 'select',
                      display: 'Select',
                      displayAsText: 'Select',
                      isSortable: true,
                  }
                : null,
            {
                id: 'label',
                display: 'Label',
                displayAsText: 'Label',
                isSortable: true,
            },
            {
                id: 'title',
                display: 'Title',
                displayAsText: 'Title',
                isSortable: true,
            },
            {
                id: 'description',
                display: 'Description',
                displayAsText: 'Description',
                isSortable: true,
            },
        ].filter(Boolean); // Remove null values
    }, [permissions]); // Recalculate columns if permissions change

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    const handleConfirmation = () => {
        if (selectedOfferId) {
            onOfferRedeem(selectedOfferId);
            handleOfferSelection(selectedOfferId);
        }
        setUserSureWantToUsePopup(false);
    };

    const handleCancel = () => {
        setUserSureWantToUsePopup(false);
    };

    const mappedItems = useMemo(() => {
        return availableOfferDetails?.length > 0
            ? availableOfferDetails.map((item, index) => ({
                  select: (
                      <EuiFlexItem grow={false}>
                          <Controller
                              render={({ field }) => (
                                  <EuiCheckbox
                                      onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          field.onChange(isChecked);
                                          
                                          if (isChecked) {
                                              setSelectedOfferId(item.id);
                                              setUserSureWantToUsePopup(true);
                                          }
                                          
                                          setValue(`${item.label}_${item.id}_${index}`, isChecked);
                                      }}
                                      id={`${item.label}_${item.id}_${index}`}
                                      checked={Boolean(field.value)}
                                      compressed
                                  />
                              )}
                              name={`${item.label}_${item.id}_${index}`}
                              control={control}
                          />
                      </EuiFlexItem>
                  ),
                  label: item.label,
                  title: item.title || 'N/A',
                  description: item.description || 'N/A',
              }))
            : [];
    }, [availableOfferDetails, setValue]);

    return (
        <>
            <EuiDataGrid
                aria-label='Offers List Datagrid'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                rowHeight={50}
                height='500px'
                gridStyle={{
                    stripes: true,
                    border: 'all',
                    header: 'underline',
                }}
                renderCellValue={({ rowIndex, columnId }) => {
                    const row = mappedItems[rowIndex];
                    return row[columnId];
                }}
            />
            {userSureWantToUsePopup && (
                <EuiOverlayMask>
                    <EuiConfirmModal
                        title='Confirm Offer Redemption'
                        onCancel={handleCancel}
                        cancelButtonText='Cancel'
                        confirmButtonText='Confirm'
                        onConfirm={handleConfirmation}
                        defaultFocusedButton='confirm'
                    >
                        Are you sure you want to redeem this offer?
                    </EuiConfirmModal>
                </EuiOverlayMask>
            )}
        </>
    );
};

export default memo(AvailableOfferTable);
