import React, { Fragment, useCallback, useState, useEffect } from 'react';
import {
    EuiDatePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiLoadingContent,
    EuiPanel,
    EuiSpacer,
} from '@elastic/eui';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TextField from '../Form/TextField';
import TextAreaField from '../Form/TextAreaField';
import useFormActionsHandler from '../../hooks/useFormActionsHandler';
import FormGroupDescription from '../formGroupDescription';
import ColorField from '../Form/ColorField';
import NumberField from '../Form/NumberField';
import SelectField from '../Form/SelectField';
import TermsAndConditions from '../../modules/Offers/OfferCreateEdit/Components/TermsAndConditions';
// import { toastsErrorMessage, toastSuccessMessage } from '../../utils/toasts';
import { useHistory } from 'react-router-dom';
import APIV5 from '../../api/axios/API5';
export const MemberShipListInputs = ({ membership }) => {
    const history = useHistory();
    const [defaultValues, setDefaultValues] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(true);

    const languages = useSelector((state) => state.language.availableLanguages);

    const screenList = [
        { value: 'Days', inputDisplay: 'Days' },
        { value: 'Expire', inputDisplay: 'Expire' },
    ];
    let startDate = moment();
    let endDate = moment();

    window.addEventListener('popstate', function (event) {
        // window.history.pushState(null, document.title, window.location.href);
        // alert('demo');
        history.push('/offers?membership-list');
    });

    const updateFormValues = useCallback(async (data) => {
        const defaultValue = {};
        if (data?.id) {
            defaultValue.internal_name = data.internal_name;
            defaultValue.translations = {
                display_name: data?.translations?.display_name,
                description: data?.translations?.description,
                terms_and_conditions: data.translations?.terms_and_conditions || {},
            };

            // if (data.translations) {
            //     for (const langId in data.translations.display_name) {
            //         defaultValue.translations.title[langId] = data.translations.display_name[langId];
            //     }
            //     for (const langId in data.translations.description) {
            //         defaultValue.translations.description[langId] = data.translations.description[langId];
            //     }
            // }

            // Set validity type and period
            // defaultValue.screen = data.validity_type;
            // if (data.validity_type === 'Days') {
            //     defaultValue.validity_period = data.validity_period?.toString();
            // } else {
            //     defaultValue.valid_from_date = data.valid_from_date ? moment(data.valid_from_date) : null;
            //     defaultValue.valid_to_date = data.valid_to_date ? moment(data.valid_to_date) : null;
            // }
            if (data.validity_period !== null) {
                defaultValue.screen = 'Days';
                defaultValue.validity_period = data.validity_period?.toString();
            } else if (data.valid_from_date && data.valid_to_date) {
                defaultValue.screen = 'Expire';
                defaultValue.valid_from_date = moment(data.valid_from_date);
                defaultValue.valid_to_date = moment(data.valid_to_date);
            } else {
                defaultValue.screen = ''; // No validity type selected
            }

            defaultValue.card_background_color = data.card_background_color;
            defaultValue.priceValue = data.price?.toString();
            defaultValue.memberShip_worth = data.membership_worth?.toString();

            return defaultValue;
        } else {
            // Return empty form for new membership
            return {
                internal_name: '',
                translations: {
                    display_name: {},
                    description: {},
                    terms_and_conditions: {},
                },
                screen: '',
                card_background_color: '#000000',
                brand_color_primary: '',
                priceValue: '',
                memberShip_worth: '',
            };
        }
    }, []);

    useEffect(() => {
        const initializeForm = async () => {
            if (membership) {
                const values = await updateFormValues(membership);
                setDefaultValues(values);
            } else {
                const emptyValues = await updateFormValues(null);
                setDefaultValues(emptyValues);
            }
            setLoading(false);
        };

        initializeForm();
    }, [membership, updateFormValues]);

    const handleMembershipUpdate = useCallback(
        async (data) => {
            try {
                const response = await APIV5.put(`restaurants/:restaurantId/memberships/${membership.id}`, data);
                if (response.success) {
                    setErrorMessage('');
                    setSubmitError(false);
                    history.push('/offers?membership-list');
                    // toastSuccessMessage('Membership successfully updated');
                } else {
                    setSubmitError(true);
                    setErrorMessage(response.errors);
                }
                return response;
            } catch (e) {
                setErrorMessage(e.errors);
                setSubmitError(true);
                // handleApiErrors(e);
                throw e;
            }
        },
        [history, membership]
    );

    const handleMembershipCreate = useCallback(
        async (data) => {
            try {
                const response = await APIV5.post('restaurants/:restaurantId/memberships', data);
                if (response.success) {
                    setErrorMessage('');
                    setSubmitError(false);
                    history.push('/offers?membership-list');
                    // toastSuccessMessage('Membership successfully created',);
                } else {
                    setSubmitError(true);
                    setErrorMessage(response.errors);
                }
                return response;
            } catch (e) {
                setErrorMessage(e.errors);
                setSubmitError(true);
                // handleApiErrors(e);
                throw e;
            }
        },
        [history]
    );

    // const handleApiErrors = (error) => {
    //     const errorFields = ['internal_name', 'translations', 'validity_type', 'price', 'worth'];

    //     for (const field of errorFields) {
    //         if (error?.errors?.[field]) {
    //             toastsErrorMessage(error.errors[field]);
    //             return;
    //         }
    //     }
    //     toastsErrorMessage('Error in managing membership');
    // };

    const onFormSaveApi = useCallback(
        async (data) => {
            const formattedData = {
                internal_name: data?.internal_name,
                active: true,
                validity_period: data.screen === 'Days' ? parseInt(data.validity_period) : null,
                valid_from_date: data.screen === 'Expire' ? moment(data.valid_from_date).format('YYYY-MM-DD') : null,
                valid_to_date: data.screen === 'Expire' ? moment(data.valid_to_date).format('YYYY-MM-DD') : null,
                card_background_color: data.card_background_color,
                price: parseFloat(data.priceValue),
                membership_worth: data.memberShip_worth.toString(),
                translations: {
                    display_name: {},
                    description: {},
                    terms_and_conditions: {},
                },
            };

            for (const property in data.translations) {
                for (const langId in data.translations[property]) {
                    formattedData.translations[property][parseInt(langId)] = data.translations[property][langId];
                }
            }

            if (membership?.id) {
                return handleMembershipUpdate(formattedData);
            } else {
                return handleMembershipCreate(formattedData);
            }
        },
        [handleMembershipCreate, handleMembershipUpdate, membership]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };
    if (loading) {
        return (
            <>
                <EuiPanel>
                    <EuiLoadingContent lines={6} />
                </EuiPanel>
                <EuiSpacer />
                <EuiPanel>
                    <EuiLoadingContent lines={2} />
                </EuiPanel>
            </>
        );
    }

    return (
        <FormProvider {...methods}>
            <div style={{ paddingBottom: '50px' }}>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
                            {/* Rest of your form fields remain the same */}
                            <TextField
                                errors={errors}
                                label='Internal Name'
                                name='internal_name'
                                placeholder='Please Enter Name'
                                rules={{ required: 'Please enter Name' }}
                                control={control}
                            />

                            {/* Languages mapping */}
                            {languages?.map((item) => (
                                <Fragment key={item.language_id}>
                                    <TextField
                                        errors={errors}
                                        isInvalid={errors?.translations?.display_name?.hasOwnProperty(item.language_id)}
                                        error={errors?.translations?.display_name?.[item.language_id]?.message}
                                        label={`${item.language_label} : Title`}
                                        name={`translations.display_name.${item.language_id}`}
                                        placeholder='Please Enter Title'
                                        rules={{ required: 'Please enter Title' }}
                                        control={control}
                                    />

                                    <TextAreaField
                                        errors={errors}
                                        isInvalid={errors?.translations?.description?.hasOwnProperty(item.language_id)}
                                        compressed={true}
                                        error={errors?.translations?.description?.[item.language_id]?.message}
                                        label={`${item.language_label} : Description`}
                                        name={`translations.description.${item.language_id}`}
                                        placeholder='Please Enter description'
                                        rules={{ required: 'Please enter description' }}
                                        control={control}
                                    />
                                </Fragment>
                            ))}

                            <div
                            // style={{
                            //     backgroundColor: 'black',
                            //     display: 'flex',
                            //     // alignItems: 'center',
                            //     gap: '10px',
                            //     flexWrap: 'nowrap',
                            // }}
                            >
                                <FormGroupDescription title='Select Expiry'>
                                    <SelectField
                                        errors={errors}
                                        name='screen'
                                        options={screenList}
                                        valueOfSelected={watch('screen')}
                                        placeholder='Please choose user validity'
                                        control={control}
                                        defaultValue={watch('screen')}
                                        rules={{ required: 'Please select validity type' }}
                                    />
                                </FormGroupDescription>
                                {watch('screen') === 'Expire' ? (
                                    <EuiFlexGroup style={{ marginBottom: '150px' }}>
                                        <EuiFlexItem>
                                            <EuiFormRow
                                                label='Coupon valid from:'
                                                fullWidth={true}
                                                isInvalid={errors.valid_from_date}
                                                error={errors.valid_from_date ? errors.valid_from_date.message : null}
                                            >
                                                <Controller
                                                    render={({ field }) => (
                                                        <EuiDatePicker
                                                            selected={watch('valid_from_date')}
                                                            showTimeSelect={false}
                                                            onChange={(value) => field.onChange(value)}
                                                        />
                                                    )}
                                                    name='valid_from_date'
                                                    control={control}
                                                    defaultValue={
                                                        watch('valid_from_date') ? watch('valid_from_date') : startDate
                                                    }
                                                    placeholder='select starting date'
                                                    isInvalid={errors.valid_from_date}
                                                    fullWidth={true}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiFormRow
                                                label='Coupon valid till: '
                                                fullWidth={true}
                                                isInvalid={errors.valid_to_date}
                                                error={errors.valid_to_date ? errors.valid_to_date.message : ''}
                                            >
                                                <Controller
                                                    render={({ field }) => (
                                                        <EuiDatePicker
                                                            selected={watch('valid_to_date')}
                                                            showTimeSelect={false}
                                                            onChange={(value) => field.onChange(value)}
                                                        />
                                                    )}
                                                    name='valid_to_date'
                                                    control={control}
                                                    defaultValue={
                                                        watch('valid_to_date') ? watch('valid_to_date') : endDate
                                                    }
                                                    placeholder='select ending date'
                                                    isInvalid={errors.valid_to_date}
                                                    fullWidth={true}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                ) : null}

                                {watch('screen') === 'Days' && (
                                    <NumberField
                                        label='Select days'
                                        isInvalid={errors?.validity_period}
                                        errors={errors}
                                        name='validity_period'
                                        placeholder='0'
                                        step='any'
                                        control={control}
                                        rules={{ required: 'Please enter number of days' }}
                                    />
                                )}
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                <FormGroupDescription title='Select Colors...' description='Select colors...'>
                                    <ColorField
                                        errors={errors}
                                        label='Brand Color (Primary color)'
                                        name='card_background_color'
                                        placeholder='Please select brand color'
                                        rules={{ required: 'Please select primary color' }}
                                        control={control}
                                        watch={watch}
                                    />
                                </FormGroupDescription>
                            </div>

                            <TermsAndConditions />

                            <NumberField
                                label='Price'
                                isInvalid={errors?.priceValue}
                                errors={errors}
                                name='priceValue'
                                placeholder='0'
                                step='any'
                                control={control}
                                rules={{ required: 'Please enter price' }}
                            />

                            <NumberField
                                label='MemberShip Worth'
                                isInvalid={errors?.memberShip_worth}
                                errors={errors}
                                name='memberShip_worth'
                                placeholder='0'
                                step='any'
                                control={control}
                                rules={{ required: 'Please enter membership worth' }}
                            />
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        </FormProvider>
    );
};
export default React.memo(MemberShipListInputs);
