import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../../components/layout/mainLayout';
import { SET_OFFERS } from '../../../reduxStore/types/offers';
import useQuery from '../../../utils/useQuery';
import OfferCreateEditForm from './Components/OfferCreateEditForm';

const OfferCreateEditPage = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const offerType = query.get('offerType');
    const offer = useSelector((state) => state.offersReducer.offer);

    const history = useHistory();

    useEffect(() => {
        return function cleanup() {
            dispatch({
                type: SET_OFFERS,
                payload: {},
            });
        };
    }, [dispatch]);

    if (!offerType) {
        history.push('/offers');
    }

    const isInMembershipOfferCreateOrEditPage =
        history?.location?.pathname?.includes('/membership/offers/edit') ||
        history?.location?.pathname?.includes('/membership/offers/create');
    return (
        <MainLayout
            backgroundColorChange={true}
            title={isEmpty(offer) ? 'Create an offer' : 'Modify an offer'}
            historyBack={isInMembershipOfferCreateOrEditPage ? false : true}
            customHistoryBack={isInMembershipOfferCreateOrEditPage ? true : false}
            customHistoryBackPath={'/offers?membership-offer-list'}
        >
            <OfferCreateEditForm
                offer={offer}
                selectedOfferType={offerType}
                isInMembershipOfferCreateOrEditPage={isInMembershipOfferCreateOrEditPage}
            />
        </MainLayout>
    );
};

export default OfferCreateEditPage;
